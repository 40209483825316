/**
 * @generated SignedSource<<67d1113ad1628046c0cf3369b615c43f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type usePanelConfigurationQuery$variables = {};
export type usePanelConfigurationQuery$data = {
  readonly panelConfiguration: {
    readonly registrationOptions: {
      readonly email: boolean;
      readonly phoneNumber: boolean;
      readonly socialApple: boolean;
      readonly socialFacebook: boolean;
      readonly socialGoogle: boolean;
    };
  } | null;
};
export type usePanelConfigurationQuery = {
  response: usePanelConfigurationQuery$data;
  variables: usePanelConfigurationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "concreteType": "RegistrationOptions",
  "kind": "LinkedField",
  "name": "registrationOptions",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "phoneNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "socialFacebook",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "socialGoogle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "socialApple",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "usePanelConfigurationQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PanelConfiguration",
        "kind": "LinkedField",
        "name": "panelConfiguration",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "usePanelConfigurationQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PanelConfiguration",
        "kind": "LinkedField",
        "name": "panelConfiguration",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1921d519f18396df529068be1e6d3989",
    "id": null,
    "metadata": {},
    "name": "usePanelConfigurationQuery",
    "operationKind": "query",
    "text": "query usePanelConfigurationQuery {\n  panelConfiguration {\n    registrationOptions {\n      phoneNumber\n      email\n      socialFacebook\n      socialGoogle\n      socialApple\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "e747e956deef8a8418b516b562a8f1ca";

export default node;
