import clsx from 'clsx';
import { ReactElement, ReactNode } from 'react';
import { useRouter } from 'next/router';
import { useUserJwtData } from 'components/templates/AuthenticationProvider/AuthenticationProvider';
import useOnePConfiguration from 'hooks/useOnePConfiguration';
import {
  SOCIAL_FACEBOOK_LOGIN_URL,
  SOCIAL_GOOGLE_LOGIN_URL,
  SOCIAL_APPLE_LOGIN_URL,
} from 'apiUrls';
import A from 'components/atoms/A/A';
import Txt from 'components/atoms/Txt/Txt';
import classes from './SocialSignUpButtons.module.scss';
import usePanelConfiguration from 'hooks/usePanelConfiguration';

export default function SocialSignUpButtons(): ReactElement {
  const panelConfiguration = usePanelConfiguration();

  const socialLoginEnabled =
    panelConfiguration?.registrationOptions?.socialFacebook ||
    panelConfiguration?.registrationOptions?.socialGoogle ||
    panelConfiguration?.registrationOptions?.socialApple;

  return socialLoginEnabled ? (
    <div className={clsx(classes.root)}>
      <div className={clsx(classes.signInWith)}>
        <Txt>Or sign up with</Txt>
      </div>
      <div className={clsx(classes.socialButtonsWrapper)}>
        {panelConfiguration?.registrationOptions?.socialFacebook && (
          <div className={clsx(classes.socialButton, classes.facebook)}>
            <A href={SOCIAL_FACEBOOK_LOGIN_URL}>facebook</A>
          </div>
        )}
        {panelConfiguration?.registrationOptions?.socialGoogle && (
          <div className={clsx(classes.socialButton, classes.google)}>
            <A href={SOCIAL_GOOGLE_LOGIN_URL}>google</A>
          </div>
        )}
        {panelConfiguration?.registrationOptions?.socialApple && (
          <div className={clsx(classes.socialButton, classes.apple)}>
            <A href={SOCIAL_APPLE_LOGIN_URL}>apple</A>
          </div>
        )}
      </div>
    </div>
  ) : (
    <></>
  );
}
