import TranslationWrapper from 'components/templates/Translation/TranslationWrapper';
import usePanelConfiguration from 'hooks/usePanelConfiguration';
import React, { Suspense, useEffect, useState } from 'react';
import { EMAIL_REGEX, USERNAME_REGEX, PHONENUMBER_REGEX } from 'utils/formHelper';
import TextInput, { Props as TextInputProps } from './TextInput';

export type Props = {} & Omit<TextInputProps, 'id' | 'name' | 'title' | 'placeholder' | 'type'>;

export const FORM_KEY = 'username';
const namespace = 'common';

function UsernameInput(props: Props, ref: React.ForwardedRef<HTMLInputElement>) {
  const panelConfiguration = usePanelConfiguration();

  const [strings, setStrings] = useState<Record<string, string>>({
    // Email registration only
    title: 'Email Address',
    placeholder: 'Enter Email Address',
    autoComplete: 'username email',
    pattern: EMAIL_REGEX.source,
    errorMessage: 'Please enter a valid email address',
  });

  useEffect(() => {
    if (
      // Phone number and email registration
      panelConfiguration?.registrationOptions.phoneNumber &&
      panelConfiguration?.registrationOptions.email
    ) {
      setStrings({
        title: 'Email Address or Phone Number',
        placeholder: 'Enter Email Address or Phone Number',
        autoComplete: 'username email tel',
        pattern: USERNAME_REGEX.source,
        errorMessage: 'Please enter a valid email address or phone number',
      });
    } else if (
      // Phone number not no email registration
      panelConfiguration?.registrationOptions.phoneNumber &&
      !panelConfiguration?.registrationOptions.email
    ) {
      setStrings({
        title: 'Phone Number',
        placeholder: 'Enter Phone Number',
        autoComplete: 'username tel',
        pattern: PHONENUMBER_REGEX.source,
        errorMessage: 'Please enter a valid phone number',
      });
    }
  }, [panelConfiguration]);

  return (
    <TranslationWrapper namespace={namespace}>
      <TextInput
        id={FORM_KEY}
        name={FORM_KEY}
        title={strings.title}
        placeholder={strings.placeholder}
        type="text"
        required
        autoComplete={strings.autoComplete}
        pattern={strings.pattern}
        overrideErrorMessage={() => {
          return strings.errorMessage;
        }}
        ref={ref}
        {...props}
      />
    </TranslationWrapper>
  );
}

const ForwardedUsernameInput = React.forwardRef<HTMLInputElement, Props>(UsernameInput);

/**
 * React behaves strangely when one of a set of sibling input elements is suspended. We wrap this in
 * its own Suspense boundary with a fallback to a <div> which is the same wrapper element used by
 * TextInput so when UsernameInput stops being suspended its tree will render cleanly into the same div.
 * @see https://legacy.reactjs.org/docs/reconciliation.html
 */
const SuspendingUsernameInput = (props: Props, ref: React.ForwardedRef<HTMLInputElement>) => {
  return (
    <Suspense fallback={<div hidden={true}></div>}>
      <ForwardedUsernameInput ref={ref} {...props} />
    </Suspense>
  );
};

const ForwardedSuspendingUsernameInput = React.forwardRef<HTMLInputElement, Props>(
  SuspendingUsernameInput,
);

export default ForwardedSuspendingUsernameInput;
